$(document).ready(function () {

  const sliderReviews = $('.tank-cleaning__reviews-slider');
  const sliderItem = $('.reviews-slider__item');
  const reviews = $('.tank-cleaning__reviews-item');
  const sliderLicenses = $('.tank-cleaning__licenses-slider');
  const sliderExpirience = $('.expirience-slider');
  const sliderExpirienceBtn  = $('.expirience-slider__nav-button');
  const sliderGalleryExpirience = $('.expirience-gallery');
  const slidesExpirience = $('.expirience-slider__slide');


  sliderLicenses.slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: false,
    infinite: true,
    dots: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 4,
          dots: true,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          dots: true,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          dots: true,
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          dots: true,
        }
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          dots: true,
        }
      },
    ]
  });


  reviews.each(function (index) {
    let logoSrc = $(this).attr('data-img-src');
    let sliderItem = '<div class="reviews-slider__item" data-reviews="reviews-item-' + (index + 1) + '">' +
                      '<img class="reviews-slider__item-img" src="'+ logoSrc +'" alt="partner-logo">' +
                      '</div>';
    sliderReviews.append(sliderItem);
  });

  if (reviews.length <= 1) {
    sliderReviews.hide();
  }



  sliderReviews.slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: false,
    infinite: true,
    dots: false,
    arrows: true,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          dots: true,
        }
      },
      {
        breakpoint: 840,
        settings: {
          slidesToShow: 3,
          dots: true,
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
          dots: true,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          dots: true,
        }
      },
    ]

  });

  sliderReviews.on('beforeChange', function(event, slick, currentSlide, nextSlide){
    let slide = $('.reviews-slider__item[data-slick-index="'+ nextSlide +'"]')
    showReview(slide);
  });

  sliderReviews.on('click', '.reviews-slider__item', function () {
    showReview($(this));
  });

  function showReview(slide) {
    const reviewID = slide.attr('data-reviews');
    reviews.removeClass('item--current');
    $('#' + reviewID).addClass('item--current');
  }

  sliderGalleryExpirience.slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: false,
    infinite: true,
    dots: false,
    arrows: true,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  });

  const slidesExpirienceCount = sliderExpirience.find('.expirience-slider__slide').length;
  let sliderExpCurrent = 1;
  const sliderExpCounterCurrent = sliderExpirience.find('.expirience-slider__nav-counter-current').text(sliderExpCurrent);
  const sliderExpCounterTotal = sliderExpirience.find('.expirience-slider__nav-counter-total').text(slidesExpirienceCount);

  sliderExpirienceBtn.on('click', function () {
    if ($(this).hasClass('nav-button--next')) {
      if (sliderExpCurrent < slidesExpirienceCount) {
         sliderExpCurrent++;
      } else {
        sliderExpCurrent = 1;
      }

    } else if (sliderExpCurrent !== 1) {
      sliderExpCurrent--;
    } else {
      sliderExpCurrent = slidesExpirienceCount;
    }
    slidesExpirience.removeClass('slide--active');
    $(slidesExpirience[sliderExpCurrent - 1]).addClass('slide--active');
    sliderExpCounterCurrent.text(sliderExpCurrent);

  });

});

