$(document).ready(function () {
  const form = $('.form');

  //fancybox modal
  $('[data-fancybox]').fancybox({
    toolbar: false,
    smallBtn: true,
    touch: false,
    autoFocus:false,
    afterLoad: function () {
      let fancyboxSlide = document.querySelectorAll('.fancybox-slide');
      fancyboxSlide.forEach(function (element) {
        bodyScrollLock.disableBodyScroll(element);
      });
    },
    beforeClose: function () {
      if($('.fancybox-slide').length === 1) {
        bodyScrollLock.clearAllBodyScrollLocks();
      }
    },
    afterClose: function () {
      if (this.src !== '#modal-photo' && this.src !== '#modal-privacy') {
        $(this.src).find('.form').trigger('reset');
        $(this.src).find('.form__error-msg--privacy').hide();
        $(this.src).find('.form__submit-button').attr('disabled', false);
        $(this.src).find('.field--invalid').removeClass('field--invalid');
      }

      if (this.src === '#modal-attach-resume') {
        let resumeFile = document.querySelector('.resume-form .form__file-upload');
        resumeFile.value = '';
        $('.form__file-list').hide();
      }
    },
  });

  function getStepModalText () {
    console.log('function getStepModalText')
  };

  $('.item__documents-link, .photo__gallery-link, .licenses-slider__item-zoom-link, .expirience-gallery__item-zoom-link, .reviews-item__license-link, .news-gallery-slider__item-zoom-link').fancybox({
    toolbar: false,
    type: 'image',
    smallBtn: true,
    touch: false,
    autoFocus:false,
    transitionEffect: false,
    afterLoad: function () {
      let fancyboxSlide = document.querySelectorAll('.fancybox-slide');
      fancyboxSlide.forEach(function (element) {
        bodyScrollLock.disableBodyScroll(element);
      });
    },
    beforeClose: function () {
      bodyScrollLock.clearAllBodyScrollLocks();
    },
  });

  //scroll to top
  $('.modal__scroll-top-button').on('click', function () {
    $('.fancybox-slide--html').animate( {scrollTop: '0px'}, 300);
  });

  //show all requirements

  $('.modal__all-requirements').on('click', function (e) {
    e.preventDefault();
    $(this).toggleClass('active');
    $('.modal__content-requirements').slideToggle(200);
  });

  //show modal-step

const btnModalStep = $('button[data-modal-content]');

btnModalStep.on('click', function () {
  const modalContentID = $(this).attr('data-modal-content');
  const modalID = $(this).attr('data-modal-src');
  const content = $(modalContentID).html();

  $(modalID).find('.modal-step-content__text').html(content);

  $.fancybox.open({
    src: modalID,
    type: 'inline',
    touch: false,
    autoFocus:false,
    afterLoad: function () {
      let fancyboxSlide = document.querySelectorAll('.fancybox-slide');
      fancyboxSlide.forEach(function (element) {
        bodyScrollLock.disableBodyScroll(element);
      });
    },
    beforeClose: function () {
      if($('.fancybox-slide').length === 1) {
        bodyScrollLock.clearAllBodyScrollLocks();
      }
    },
    afterClose: function () {
      $(this.src).find('.form').trigger('reset');
      $(this.src).find('.form__error-msg--privacy').hide();
      $(this.src).find('.form__submit-button').attr('disabled', false);
      $(this.src).find('.field--invalid').removeClass('field--invalid');
    }

  })
})

});
