$(document).ready(function () {
  const menu = $('.modal-menu');
  const menuSection = $('.grid__menu-section');
  const menuFooter = $('.modal-menu__menu-footer');


  $('.menu__close-button, .first-line__menu-caller').on('click', function () {
    menu.toggleClass('modal-menu--active');

    if (menu.hasClass('modal-menu--active')) {

      const wWidth = $(window).width();

      if (wWidth > 1140) {
        menuSection.each(function (index) {   //smooth show menu sections
          let el = $(this);
          setTimeout(function () {
            el.slideDown(200);
          },index * 80);
        });
      } else {
        menuSection.show();
      }

      if (wWidth > 767) {
        setTimeout(function() {menuFooter.css({'opacity': '1'});}, (menuSection.length + 1) * 80);
      }

      bodyScrollLock.disableBodyScroll(document.querySelector('.modal-menu'));
    } else {
      menuSection.hide();
      menuFooter.css({'opacity': '0'});
      bodyScrollLock.clearAllBodyScrollLocks();
    }
  });

});
