$(document).ready(function () {
  const form = $('.form'),
      formPhoto = $('.form--photo'),
      dateField = '.field--date',
      requaredField = '.field--requared';
  submitBtn = $('.form__submit-button');
  privacyCheckbox = $('#privacy-checkbox');
  falseInfoCheckbox = $('#false-info-checkbox');

  let errTotal = false;
  let userPhotoFile;
  let userResumeFile;

  // == lokalize ==
  let errorPhoneMessage = gettext('Введите корректный номер');
  errorPhoneMessage = interpolate(errorPhoneMessage);

  // == /lokalize ==

  //disable form submin on enter
  form.bind("keypress", function (e) {
    if (e.keyCode == 13) {
      e.preventDefault();
      return false;
    }
  });

  //add input outer
  $('.resume-form ' + requaredField +
      ', .comment-form ' + requaredField +
      ', .consult-form ' + requaredField +
      ', .resume-form ' + dateField +
      ', .order-form ' + requaredField).wrap('<div class="form__input-outer"></div>');

  //add calendar icon
  $(dateField).each(function () {
    $(this).parent().append('<span class="calendar-icon"></span>')
  });

  //add datepicker

  datepickerInit();

  function datepickerInit() {
    $(dateField).datepicker({
      toggleSelected: false,
      todayButton: new Date(),
      onSelect: function () {
        $(dateField).each(function () {
          if ($(this).val() !== '' && $(this).val() !== '__.__.____' && $(this).val() !== '__.____') {
            $(this).parent().removeClass('field--invalid');
          }
        });
      },
    });
  }


  (function markRequaredField() {
    form.find(requaredField).each(function () {
      if (!$(this).parents('.form__input-section').length) {
        $(this).closest('.form__input-wrapper').find('.form__label:not(.label--checkbox)').append('<span class="label__asterisk"> *</span>');
      }
    });
  })();
  // COMMENTED BECAUSE VALIDATION OF INTLTELINPUTMASK IS USED
  // function validatePhone(str) {
  //   let countNum = 0;

  //   for (var i = 0; i < str.length; i++) {
  //     if (!isNaN(str[i]) && str[i] !== ' ') {
  //       countNum++;
  //     }
  //   }
  //   if (countNum == 11) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  $('.input-wrapper--radio-gender .label--radio').on('click', function () {
    $(this).parent().removeClass('field--invalid');
  });

  function validateGender() {
    let genderSection = $('.input-wrapper--radio-gender');
    let result = 0;
    genderSection.find('.form__input-radio').each(function () {
      let check = $(this).prop('checked');
      if (check) {
        result++;
      }
    });
    return result;
  }

  function validate(form) {
    let emptyField = 0;
    let invalidPhone = 0;
    let invalidSelect = 0;
    let emptyGender = false;

    // проверка на пустые поля
    form.find(requaredField).each(function () {
      clearError($(this));
      let value = $(this).val();
      value = (value || '').trim();
      if (value === '') {
        $(this).parent().addClass('field--invalid');
        $(this).val('');
        emptyField++;

      } else if (($(this).hasClass('field--date'))) { //проверка ввода даты
        if (value === '__.__.____' || $(this).val() === '__.____') {
          $(this).parent().addClass('field--invalid');
          emptyField++;
        }

      } else if (($(this).hasClass('field--phone'))) { //Проверка полного номера
          iti.forEach(field => {
            if (field.telInput.classList.contains('focused')) {
              if (!field.isValidNumber()) {
                invalidPhone++;
                $(this).closest('.form__input-outer').addClass('field--invalid');
                $(this).closest('.form__input-wrapper').find('.form__error-msg').html(errorPhoneMessage)
              } else {
                invalidPhone = 0;
              }
            }
          }) 
      }

      if ($(this).hasClass('form__select')) {
        if ($(this).find('option:selected').val() === '') {
          invalidSelect++;
          $(this).parent().addClass('field--invalid');
          $(this).closest('.form__input-wrapper').find('.form__error-msg').show();
        }
      }
    });

    let genderSection = $('.input-wrapper--radio-gender');
    if (genderSection.length) {
      if (validateGender()) {
        genderSection.removeClass('field--invalid');
        emptyGender = false;
      } else {
        genderSection.addClass('field--invalid');
        emptyGender = true;
      }
    }

    if (emptyField || invalidPhone || invalidSelect || emptyGender) {
      errTotal = true;
    } else {
      errTotal = false;
    }
  };

  function clearError(elem) {
    elem.parent().removeClass('field--invalid');
    elem.closest('.form__input-outer').removeClass('field--invalid');;
    if (elem.hasClass('form__select')) {
      elem.closest('.form__input-wrapper').find('.form__error-msg').hide();
    }
  };

  //clear field on focus
  $(requaredField).on('input', function () {
    clearError($(this));
  });


  $('#residence-on-registration').on('click', function () {
    copyRegistration();
  });
  $('#registration').on('input', function () {
    copyRegistration();
  });

  function copyRegistration() {
    let check = $('#residence-on-registration').prop('checked');
    let address = $('#registration').val();
    let residence = $('#residence');
    if (check) {
      residence.val(address);
      residence.attr('disabled', true);
    } else {
      residence.attr('disabled', false);
    }
  }

  //============== management form ======================
  function manageForm(section, counter, action) {
    let inputTotalForms;
    let inputDelete;
    section.find('input').each(function () {
      let inputID = $(this).attr('id').split('-');
      let inputName = inputID[inputID.length - 1];
      if (inputName == 'TOTAL_FORMS') {
        inputTotalForms = $(this);
      } else if (inputName == 'DELETE') {
        if (action == 'del') {
          if (+inputID[1] == counter) {
            $(this).attr('value', '1');
          }
        } else {
          inputDelete = $(this);
        }
      }
    });

    let value = inputTotalForms.attr('value');
    if (action === 'add') {                       //add block
      inputTotalForms.attr('value', +value + 1);

      let inputDeleteCopy = inputDelete.clone();
      let newInputName = inputDeleteCopy.attr('name').split('-');
      newInputName[1] = counter;
      newInputName = newInputName.join('-');
      inputDeleteCopy.attr('name', newInputName);
      inputDeleteCopy.attr('id', 'id_' + newInputName);
      inputDeleteCopy.removeAttr('value');
      inputDeleteCopy.appendTo(section);

    } else {                                      //del block
      inputTotalForms.attr('value', +value - 1);
    }
  };


  //===========  clone language select ============
  let cloneLangCounter = 0;
  const managementForm = $('.form__language-section .form__management-form');
  const languageLine = $('.form__language-section .form__input-wrapper:not(.language-clone)');
  const languageSelect = languageLine.find('.form__select');
  const languageAddBtn = $('.add-button--language');

  languageAddBtn.on('click', function (e) {
    e.preventDefault();
    cloneLangCounter++;

    //edit management form
    manageForm(managementForm, cloneLangCounter, 'add');

    languageSelect.closest('.form__language-section').find('.form__input-wrapper').last()
        .after('<div class="form__input-wrapper input-wrapper--inline language-clone clone-' + cloneLangCounter + '"></div>');
    let languageLineClone = languageLine.siblings('.clone-' + cloneLangCounter);

    languageSelect.each(function () {
      let selectClone = $(this).clone();
      selectClone.removeClass('select-hidden').appendTo(languageLineClone);
      let selectID = $(this).attr('id').split('-');
      let selectName = $(this).attr('name').split('-');
      selectID[1] = cloneLangCounter;
      selectID = selectID.join('-');
      selectClone.attr('id', selectID);
      selectName[1] = cloneLangCounter;
      selectName = selectName.join('-');
      selectClone.attr('name', selectName);
    });

    setSelectcStyle(); //init for clone select
  });

  setSelectcStyle(); // first init

  //===========  /clone language select ============


  //add other programms checkbox
  $('.form__checkbox.checkbox--other').on('change', function () {
    let textField = $(this).parent().find('.form__textarea');
    let check = $(this).prop('checked');
    if (check) {
      textField.removeAttr('disabled');
    } else {
      textField.val('');
      textField.attr('disabled', true);
    }
  });

  //no work expirience
  $('#no-work-expirience').on('change', function () {
    const sectionExp = $('.form__input-section.input-section--expirience');
    setRequared($(this), sectionExp);
  });

  //anonymous comment
  $('#anonymous-comment').on('change', function () {
    const sectionCommentAuthor = $('.comment-form__author');
    setRequared($(this), sectionCommentAuthor);
  });

  function setRequared(e, section) {
    let check = e.prop('checked');
    let inputField = section.find('.form__textarea, .form__input');
    let invalidField = section.find('.field--invalid');
    let asterisk = section.find('.label__asterisk');
    if (check) {
      inputField.attr('disabled', true);
      inputField.removeClass('field--requared').addClass('field--disabled');
      inputField.val('');
      invalidField.removeClass('field--invalid');
      asterisk.hide();
    } else {
      inputField.removeAttr('disabled');
      inputField.addClass('field--requared').removeClass('field--disabled');
      asterisk.show();
    }
  }


  //copy work expirience line
  const sectionExp = $('.form__input-section.input-section--expirience');
  var cloneJobCount = 0;
  $('.add-button--job').on('click', function (e) {
    e.preventDefault();
    cloneJobCount++;
    if ($(window).width() > 900) {
      copyInputBlock(sectionExp, cloneJobCount);
    } else {
      copySection(sectionExp, cloneJobCount);
    }
  });

  //copy relative line
  const sectionRel = $('.form__input-section.input-section--relatives');
  var cloneRelativeCount = 0;
  $('.add-button--relative').on('click', function (e) {
    e.preventDefault();
    cloneRelativeCount++;
    if ($(window).width() > 900) {
      copyInputBlock(sectionRel, cloneRelativeCount);
    } else {
      copySection(sectionRel, cloneRelativeCount);
    }
  });

  //copy leave country
  const sectionLeave = $('.form__input-section.input-section--leave');
  var cloneLeaveCount = 0;

  $('.add-button--leave').on('click', function (e) {
    e.preventDefault();
    cloneLeaveCount++;
    if ($(window).width() > 900) {
      copyInputBlock(sectionLeave, cloneLeaveCount);
    } else {
      copySection(sectionLeave, cloneLeaveCount);
    }

  });

  //clone only input
  function copyInputBlock(section, counter) {
    let inputFields = section.find('.section__input:not(.clone-section)');
    let lastSection = section.find('.input-inline__section:last-of-type');

    inputFields.each(function () {
      let cloneSection = $(this).clone().addClass('clone-section clone-' + counter);
      cloneSection.appendTo($(this).parent());

      setInputID(cloneSection, counter);
    });

    datepickerInit();
    // phoneMaskInit();
    dateMaskInit();
    manageForm(section.prev(), counter, 'add');

    let delBtn = '<button class="section__del-button clone-' + counter + '">Удалить</button>';
    lastSection.find('.section__input:last-child').append(delBtn);
  }

  //clone full section on mobile
  function copySection(section, counter) {
    let classList = section.attr('class').split(/\s+/);

    let selector = '';
    for (i = 0; i < classList.length; i++) {
      selector = selector + '.' + classList[i];
    }
    let cloneSection = section.clone().addClass('clone-section clone-' + counter);
    let lastSection = $(selector).last();
    lastSection.after(cloneSection);

    let delBtn = '<button class="section__del-button clone-' + counter + '">Удалить</button>';
    cloneSection.append(delBtn);

    setInputID(cloneSection, counter);

    datepickerInit();
    // phoneMaskInit();
    dateMaskInit();
    manageForm(section.prev(), counter, 'add');
  }

  //sets input fiels other ID and name
  function setInputID(clone, counter) {
    clone.find('.form__input, .form__textarea').each(function () {
      let name = $(this).attr('name').split('-');
      let id = $(this).attr('id').split('-');
      id[1] = counter;
      id = id.join('-');
      name[1] = counter;
      name = name.join('-');
      $(this).attr('name', name);
      $(this).attr('id', id);
      $(this).val('');
    });
  }

  // remove added input fields
  form.on('click', '.section__del-button', function (e) {
    e.preventDefault();
    let classList = $(this).attr('class').split(/\s+/);
    let numClone = classList[1].split('-');
    numClone = numClone[1];

    if ($(window).width() > 900) {
      let managementForm = $(this).closest('.form__input-section').prev();
      $(this).closest('.form__input-inline').find('.section__input.' + classList[1]).remove();

      manageForm(managementForm, numClone, 'del');
    } else {
      let managementForm = $(this).closest('.form__input-section').prevAll('.form__management-form')[0];
      $(this).parent().remove();
      manageForm($(managementForm), numClone, 'del');
    }
  });

  //phone input mask+
  const phoneField = document.querySelectorAll('.field--phone');

  // phoneMaskInit();
  // COMMENTED BECAUSE INTLTELINPUT MASK IS USED
  // function phoneMaskInit() {
  //   phoneField.forEach(function (element) {
  //     const phoneMask = IMask(
  //         element, {
  //           mask: '+{7}(000)-000-00-00',
  //           placeholderChar: '_',
  //           lazy: false,
  //           overwrite: false,
  //         });
  //   });
  // }
  /////////////intlTelInput Mask///////////////////////
    var iti =[];

    phoneField.forEach((field) => {
      iti.push(window.intlTelInput(field, {
        nationalMode: true,
        formatOnDisplay: true,
        separateDialCode: false,
        autoHideDialCode: true,
        autoPlaceholder: "aggressive",
        initialCountry: "auto",
        placeholderNumberType: "MOBILE",
        geoIpLookup: function(callback) {
          $.get('https://ipinfo.io', function() {}, "jsonp").always(function(resp) {
            var countryCode = (resp && resp.country) ? resp.country : "";
            callback(countryCode);
          });
        },
        utilsScript: "./utils.js"
      }));
    });

    iti.forEach((field) => {
      field.telInput.addEventListener('focus', () => {
          iti.forEach(field => {
              field.telInput.classList.remove('focused');
          });
          field.telInput.classList.add('focused');
      });
    })
    
    $(".field--phone").on("countrychange", function(e,countryData ){
      iti.forEach((field) => {
        var selectedCountryData = field.getSelectedCountryData();
        var newPlaceholder = intlTelInputUtils.getExampleNumber(selectedCountryData.iso2, true, intlTelInputUtils.numberFormat.INTERNATIONAL);
        field.setNumber("");
        var mask1 = newPlaceholder.replace(/[1-9]/g, "0");
        $(field.telInput).mask(mask1);
              
      })
  })
  iti.forEach((field) => {
    field.promise.then(() => {
        $(".field--phone").trigger("countrychange");
    })
})
  ///////////////////////////////////

  dateMaskInit();

  function dateMaskInit() {
    let dateField = document.querySelectorAll('.field--date:not(.field--date-m-Y)');
    dateField.forEach(function (element) {
      var dateMask = IMask(
          element, {
            mask: Date,
            placeholderChar: '_',
            lazy: false,
            overwrite: false,
          });
    });

    let dateMonthField = document.querySelectorAll('.field--date-m-Y');
    dateMonthField.forEach(function (element) {
      var dateMask2 = IMask(
          element, {
            mask: 'm{.}Y',
            blocks: {
              m: {
                mask: IMask.MaskedRange,
                from: 1,
                to: 12,
                maxLength: 2,
              },
              Y: {
                mask: IMask.MaskedRange,
                from: 1900,
                to: 2100,
                maxLength: 4,
              }
            },
            placeholderChar: '_',
            lazy: false,
            overwrite: false,
          });
    });
  }

  //check privacy

  let checkPrivacy = (!privacyCheckbox.length);
  let checkFalseInfo = (!falseInfoCheckbox.length);

  $('.form__checkbox.checkbox--privacy').on('change', function () {
    showPrivacyError($(this));
    checkPrivacy = $(this).prop('checked');
    if (falseInfoCheckbox.length) {
      checkFalseInfo = falseInfoCheckbox.prop('checked');
    }
    enableSubminBtn($(this));
  });

  falseInfoCheckbox.on('change', function () {
    checkPrivacy = privacyCheckbox.prop('checked');
    checkFalseInfo = $(this).prop('checked');
    enableSubminBtn($(this));
  });

  function showPrivacyError(elem) {
    const privacyError = elem.closest('.form__input-wrapper').find('.form__error-msg--privacy');
    if (elem.prop('checked')) {
      privacyError.css('display', 'none');
    } else {
      privacyError.css('display', 'block');
    }
  }

  function enableSubminBtn(elem) {
    const button = elem.closest('.form').find('.form__submit-button');
    if (checkPrivacy && checkFalseInfo) {
      button.removeAttr('disabled');
    } else {
      button.attr('disabled', true);
    }
  }

  //  ================SEND FORMS=============

  form.on('submit', function (e) {
    e.preventDefault();

    const button = form.find('.form__submit-button');

    clearError($(this));
    validate($(this));

    if ($(this).hasClass('resume-form')) {
      $('.modal--thanks .modal__thank-text').hide();
      $('.modal--thanks .thank-text--resume').show();
    } else if ($(this).hasClass('consult-form')) {
      $('.modal--thanks .modal__thank-text').hide();
      $('.modal--thanks .thank-text--request').show();
    } else if ($(this).hasClass('subscribe__form')) {
      $('.modal--thanks .modal__thank-text').hide();
      $('.modal--thanks .thank-text--subscribe').show();
    } else {
      $('.modal--thanks .modal__thank-text').hide();
      $('.modal--thanks .thank-text--basic').show();
    }

    if (!errTotal) {
      let data = new FormData($(this)[0]);
      button.attr('disabled', true);

      //check uploaded files
      let resumeFile = document.querySelector('.resume-form .form__file-upload');
      if ($(resumeFile).length) {
        if (resumeFile.files.length) {
          data.append('file', userResumeFile);
        }
      }

      let photoFile = document.querySelector('.form--photo .form__file-upload');
      if ($(photoFile).length) {
        if (photoFile.files.length) {
          data.append('photo', userPhotoFile);
        }
      }

      var url = $(this).attr('action');

      function sendAjax() {
        $.ajax(url, {
          method: 'post',
          data: data,
          processData: false,
          contentType: false,
          success: function () {
            $.fancybox.close();
            $.fancybox.open({
                src: '#modal-thanks',
                  opts: {
                    afterLoad: function () {
                      let fancyboxSlide = document.querySelectorAll('.fancybox-slide');
                      fancyboxSlide.forEach(function (element) {
                        bodyScrollLock.disableBodyScroll(element);
                      });
                    },
                    beforeClose: function () {
                      button.removeAttr('disabled');
                      bodyScrollLock.clearAllBodyScrollLocks();
                    }
                  }
                },
                {
                  autoFocus: false,
                  touch: false,
                });

            if ($(this).hasClass('resume-form')) {
              clearResumeForm($(this));
            }

            form.trigger('reset');

            const enableSubmit = form.find('.checkbox--privacy, ').attr('checked');
            form.find('.form__submit-button').attr('disabled', enableSubmit);
            form.find('.field--disabled').removeClass('field--disabled').removeAttr('disabled').addClass('field--requared');

            //metrika
            ym(65116147, 'reachGoal', 'order');
          }
        });
      }

      console.log('recapcha', RECAPTCHA_KEY);
      if (RECAPTCHA_KEY !== '') {
        grecaptcha.execute(RECAPTCHA_KEY, {action: 'generic'}).then(token => {
          data['g-recaptcha-response'] = token;
          data.append('captcha', token);
          sendAjax();
        });

      } else {
        sendAjax();
      }

    } else if ($(this).hasClass('resume-form') && !$(this).hasClass('form--attach')) {
      scrollToError();
      showWidgetError()
    }

  });

//scroll to first Error
  function scrollToError() {
    let firstError = $('.field--invalid').first();
    $('html, body').stop().animate({
      scrollTop: firstError.offset().top - 60
    }, 400);
    return false;
  }

//show widget error
  function showWidgetError() {
    if ($(window).width() > 767) {
      let widget = $('.error-widget');
      widget.show();
      setTimeout(function () {
        widget.fadeOut(600);
      }, 5000);
    }

  }

//close widget error on close
  $('.error-widget__close').on('click', function () {
    let widget = $('.error-widget');
    widget.fadeOut(300);
  });


//очистка формы резюме
  function clearResumeForm(form) {
    form.trigger('reset');

    //delete attached files
    let resumeFile = document.querySelector('.resume-form .form__file-upload');
    if ($(resumeFile).length) {
      resumeFile.value = '';
    }
    let photoFile = document.querySelector('.form--photo .form__file-upload');
    if ($(photoFile).length) {
      photoFile.value = '';
      $('.resume-form__photo-uploaded').remove();
    }

    let selects = $('.select');
    selects.each(function () {
      $(this).removeClass('select--changed');
      $(this).find('.form__select option').removeAttr('selected');
      let optionDefautl = $(this).find('.form__select option').first();
      optionDefautl.attr('selected', 'selected');

      $(this).find('.select-styled').text(optionDefautl.text());
    });

    $('.language-clone').remove();

    $('.form__file-list').hide();

  }

  // check type upload file
  function loadMime(file, mimes, callback) {

    function check(bytes, mime) {
      for (var i = 0, l = mime.mask.length; i < l; ++i) {
        if ((bytes[i] & mime.mask[i]) - mime.pattern[i] !== 0) {
          return false;
        }
      }
      return true;
    }

    var blob = file.slice(0, 4); //read the first 4 bytes of the file
    var reader = new FileReader();
    reader.onloadend = function (e) {
      if (e.target.readyState === FileReader.DONE) {
        var bytes = new Uint8Array(e.target.result);
        for (var i = 0, l = mimes.length; i < l; ++i) {

          if (check(bytes, mimes[i])) {
            return callback(true); //callback("Mime: " + mimes[i].mime + " <br> Browser:" + file.type);
          }
        }
        return callback(false); //callback("Mime: unknown <br> Browser:" + file.type);
      }
    };
    reader.readAsArrayBuffer(blob);
  }

  function setPhoto(file) {
    var reader = new FileReader();
    reader.onload = (function (theFile) {
      return function (e) {
        // Render thumbnail.
        const targetBlock = document.querySelector('.resume-form__employee-photo');
        var span = document.createElement('span');
        $(span).addClass('resume-form__photo-uploaded');
        span.innerHTML = ['<img class="resume-form__photo-uploaded-img" title="', escape(theFile.name), '" src="', e.target.result, '" />'].join('');
        $(targetBlock).find('.resume-form__photo-uploaded, .resume-form__employee-photo-wrapper').remove();
        targetBlock.insertBefore(span, null);
      };
    })(file);
    reader.readAsDataURL(file);
  }

  const photoUpload = document.querySelector('.form--photo .form__file-upload');

  if ($(photoUpload).length) {
    photoUpload.onchange = function () {
      //List of known mimes
      var mimes = [
        {
          mime: 'image/jpeg',
          pattern: [0xFF, 0xD8, 0xFF],
          mask: [0xFF, 0xFF, 0xFF],
        },
        {
          mime: 'image/png',
          pattern: [0x89, 0x50, 0x4E, 0x47],
          mask: [0xFF, 0xFF, 0xFF, 0xFF],
        },
      ];

      loadMime(photoUpload.files[0], mimes, function (result) {
        if (result) {
          setPhoto(photoUpload.files[0]);
          $.fancybox.close();
          $(photoUpload).closest('form').find('.form__error-msg').hide();

          userPhotoFile = photoUpload.files[0];

        } else {
          $(photoUpload).closest('form').find('.form__error-msg').show();
        }
      });
    };
  }


  const resumeUpload = document.querySelector('.resume-form .form__file-upload');

  if ($(resumeUpload).length) {
    resumeUpload.onchange = function () {

      //List of known mimes
      var mimes = [
        {
          mime: 'text/css',
          pattern: [0xCD, 0xE0, 0xE7],
          mask: [0xFF, 0xFF, 0xFF],
        },
        {
          mime: 'text/css',
          pattern: [0xD, 0xA, 0x71, 0x77],
          mask: [0xF, 0xF, 0xFF, 0xFF],
        },
        {
          mime: 'application/pdf',
          pattern: [0x25, 0x50, 0x44, 0x46],
          mask: [0xFF, 0xFF, 0xFF, 0xFF],
        },
        {
          mime: 'application/msword',
          pattern: [0xD0, 0xCF, 0x11, 0xE0],
          mask: [0xFF, 0xFF, 0xFF, 0xFF],
        },
        {
          mime: 'application/rtf',
          pattern: [0x7B, 0x5C, 0x72, 0x74],
          mask: [0xFF, 0xFF, 0xFF, 0xFF],
        },
        {
          mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          pattern: [0x50, 0x4B, 0x3, 0x4],
          mask: [0xFF, 0xFF, 0xFF, 0xFF],
        },
      ];

      loadMime(resumeUpload.files[0], mimes, function (result) {
        if (result) {
          userResumeFile = resumeUpload.files[0];
          let fileList = $('.form__file-list');
          $(resumeUpload).removeClass('field--invalid');
          $(resumeUpload).closest('.form__upload-wrapper').find('.form__error-msg').hide();
          fileList.show().html('<li class="form__file-list-item">' + userResumeFile.name + '<button class="form__file-list-del">Удалить файл</button></li>');

        } else {
          $(resumeUpload).addClass('field--invalid');
          $(resumeUpload).closest('.form__upload-wrapper').find('.form__error-msg').show();
        }
      });

    };
  }


  //del resume file

  $('.form__file-list').on('click', '.form__file-list-del', function () {
    let resumeFile = document.querySelector('.resume-form .form__file-upload');
    resumeFile.value = '';
    $(this).parent().remove();
    $('.form__file-list').hide();
  });

  form.trigger('reset');

});

//Открытие модального окна для выбора: 'соискатель/сварщик'
const career_welder_btn = document.getElementById('career_welder_btn')
const welder_modal = document.getElementById('wrapper-welder-modal')
const exit = document.getElementById('welder-exit')

$(career_welder_btn).on('click', function() {
  $(welder_modal).css({
    'display': 'flex'
  })
})

$(exit).on('click', function() {
  $(welder_modal).css({
    'display': 'none'
  })
})