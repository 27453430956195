$(document).ready(function () {

  var markerPath = $('#map').attr('data-marker-path');
  var map = document.querySelector('#map');
  var centerMap = ($(window).width() <= '640') ? [56.296469, 44.065300] : [56.296469, 44.070299];

  if ($(map).length) {

    const headOfficeId = $('.float-contacts__offices-list').attr('data-head-office-id');
    const headOffice = $('.offices-list__item[data-office-id="' + headOfficeId + '"]');
    var defaultCoordPlace = headOffice.attr('data-coordplace').split(', ');
    var defaultCoordCenter = ($(window).width() < 640) ? defaultCoordPlace : getCenterCoord(defaultCoordPlace);

    function getCenterCoord(strCoordPlace) {
      var coordCenter = [];
      var coordPlace = [];

      for (var i = 0; i < strCoordPlace.length; i++) {
        coordPlace.push(+(strCoordPlace[i]));
      }
      for (var i = 0; i < coordPlace.length; i++) {
        coordCenter.push(coordPlace[i]);
      }
      coordCenter[1] = coordCenter[1] + 0.005;

      return ($(window).width() < 640) ? coordPlace : coordCenter;
    }

    var movingToNewMarker = function (coord) {

      myMap.panTo(getCenterCoord(coord), {
        flying: true
      });
      var newPlacemark = new ymaps.Placemark(
        coord, {
          balloonContent: '',
        }, {
          iconLayout: 'default#image',
          iconImageHref: markerPath,
          iconImageSize: ($(window).width() < 640) ? [67, 66] : [120, 118],
          iconImageOffset: ($(window).width() < 640) ? [-15, -55] : [-30, -100],
        }
      );
      myMap.geoObjects.removeAll();
      myMap.geoObjects.add(newPlacemark);
    };

    $('.offices-list__item').not('.item--current').on('click', function () {
      var strCoordPlace = $(this).attr('data-coordPlace').split(', ');

      if (!$('.float-contacts__offices-list').hasClass('list--active')) {
        movingToNewMarker(strCoordPlace);
      }
    });

    var onIntersection = function (entries) {
      for (const entry of entries) {
        if (entry.intersectionRatio > 0) {
          ymaps.ready(mapsInit);
          observer.unobserve(entry.target);
        }
      }
    };

    if ($('#map').length) {
      var observer = new IntersectionObserver(onIntersection);
      observer.observe(map);
    }

    function mapsInit() {
      myMap = new ymaps.Map('map', {
        center: defaultCoordCenter,
        zoom: 16,
        controls: ['zoomControl']
      });

      var Nizhniy = new ymaps.Placemark(defaultCoordPlace, {
        balloonContent: ''
      }, {
        hasBalloon: false,
        iconLayout: 'default#image',
        iconImageHref: markerPath,
        iconImageSize: ($(window).width() < 640) ? [67, 66] : [120, 118],
        iconImageOffset: ($(window).width() < 640) ? [-15, -55] : [-30, -100],
      });

      myMap.geoObjects.add(Nizhniy);
      myMap.behaviors.disable('scrollZoom');
    };
  }

});