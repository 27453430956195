$(document).ready(function () {

  const filterBtn = $('.widget__show-filter-button');
  const clearFilterBtn = $('.form__clear-filter-button');
  const filterForm = $('.form-news-filter');
  const filterListItem = filterForm.find('.select-options li');
  const searchField = filterForm.find('.input--search');
  const gridContent = $('.main-grid__content');

  clearFilterForm();

  if (filterForm) {
    const urlParams = new URLSearchParams(window.location.search);
    const searchParam = urlParams.get('search');
    const yearParam = urlParams.get('year');
    const monthParam = urlParams.get('month');
    const tagsParam = urlParams.get('news-filter-tags');

    if (yearParam) {
      setFiltersParam('#news-filter-years', yearParam);
      showClearBtn();
    }
    if (monthParam) {
      setFiltersParam('#news-filter-month', monthParam);
      showClearBtn();
    }
    if (tagsParam) {
      setFiltersParam('#news-filter-tags', tagsParam);
      showClearBtn();
    }

    // cancel send on Enter
    filterForm.keydown(function(event) {
      if (event.keyCode == 13) {
        event.preventDefault();
        return false;
      }
    });

    //set filters from URL
    function setFiltersParam(selectID, param) {
      let value;
      const select = $(selectID);
      select.find('option').each(function () {
        if ($(this).attr('value') === param) {
          value = $(this).text();
          $(this).attr('selected', 'selected');
        }
      });
      select.parent().find('.select-styled').text(value);
      select.parent().addClass('select--changed');
    }

  }

  // show/hide filter form
  filterBtn.on('click', function () {
    $(this).toggleClass('active');
    filterForm.slideToggle(400);
  });

  clearFilterBtn.on('click', function (e) {
    e.preventDefault();
    clearFilterForm();
    //hide button
    $(this).parent().hide();
    sendFilter();
  });

  // filter selected
  filterListItem.on('click', function () {
    showClearBtn();
    sendFilter();
  });

  //seach input
  searchField.on('input', _.debounce(runFilters, 400));

  function showClearBtn() {
    clearFilterBtn.parent().show();
  }
  function hideClearBtn() {
    clearFilterBtn.parent().hide();
  }

  function clearFilterForm() {
    filterForm.trigger('reset');
    const select = filterForm.find('.select');

    select.removeClass('select--changed');
    select.each(function () {
      let defaultValue = $(this).find('option[hidden]').html();
      let selectStyled = $(this).children('.select-styled');
      selectStyled.html(defaultValue);
      $(this).find('option[selected]').removeAttr('selected');
      $(this).find('select').val('');
    });
  }

  function runFilters() {
    if ($(this).val().trim()) {
      showClearBtn();
      sendFilter();
    } else if (!$('.select--changed').length) {
      hideClearBtn();
      sendFilter();
    } else {
      sendFilter();
    }
  }

  function sendFilter() {

    let url = filterForm.attr('action');
    let data = filterForm.serialize();
    $.ajax(url, {
      method: 'get',
      data: data,
      success: function (result) {
        showResult(result);
      }
    });
  }

  function showResult(result) {
    gridContent.html('').append(result);
    formatPagination();
  }

  // ================= pagination =================

  formatPagination();

  function formatPagination() {
    const pageItem = $('.item--num-page');
    const pageNum = pageItem.length;
    let pageCurrentNum = 1;

    pageItem.map(function (index) {
    if ($(this).hasClass('item--current-page')) {
      pageCurrentNum = index + 1;
      let pageMask = pagination(pageCurrentNum, pageNum);

      pageItem.hide();
      pageMask.forEach(function(arrElem, arrIndx){

          if (arrElem == '...') {
           let truncateIndex = pageMask[arrIndx + 1];
           pageItem.eq(truncateIndex - 2).html('...').show();

           return;
          }
          pageItem.each(function () {
            let value = $(this).find('.item--pagination-link').text();
            if (value == pageMask[arrIndx]) {
              $(this).show();
           }
        })
      });
      return false;
    }
  });
  }

  function pagination(c, m) {
    var current = c,
        last = m,
        delta = 1,
        left = current - delta,
        right = current + delta + 1,
        range = [],
        rangeWithDots = [],
        l;

    for (let i = 1; i <= last; i++) {
        if (i == 1 || i == last || i >= left && i < right) {
            range.push(i);
        }
    }

    for (let i of range) {
        if (l) {
            if (i - l === 2) {
                rangeWithDots.push(l + 1);
            } else if (i - l !== 1) {
                rangeWithDots.push('...');
            }
        }
        rangeWithDots.push(i);
        l = i;
    }

    return rangeWithDots;
}

});
