$(document).ready(function () {

  //Call search form
  $('.form__search-caller').on('click', function () {
    if (!$(this).hasClass('search-caller--active')) {
      callSearch();
    } else {
      if( $(this).parent().find('.form__input-search').val() == '' ) {
        closeSearch();
      } else {
        //отправка формы
        console.log('send-form');
      }
    }

  });

  //Close search form on substrate
  $('.search__bg-substrate').on('click', function () {
    closeSearch();
  });

  function callSearch() {
    $('.search__bg-substrate').addClass('bg-substrate--active');
    $('.form__search-caller').addClass('search-caller--active');
    $('.form__input-search').addClass('input-search--active').focus();
  }

  function closeSearch() {
    $('.search__bg-substrate').removeClass('bg-substrate--active');
    $('.form__search-caller').removeClass('search-caller--active');
    $('.form__input-search').removeClass('input-search--active');
    $('.search__form').trigger('reset');
  }

  $(window).scroll(function(){
   closeSearch();
  });

  //scroll main screen
  const mainScreen = $('.main-screen');
  if (mainScreen.length) {
    if ($(window).width() > 1024 && $(window).height() > 768) {

      let indicator = new WheelIndicator({
        elem: document.querySelector('.main-screen'),
        callback: function(e){
          let scroll = $(document).scrollTop();
          let screenHeight = $(window).height();

          if (e.direction === 'down') {
            $('html, body').stop().animate({
              scrollTop: screenHeight + 3,
            }, 500);

          } else {
            $('html, body').stop().animate({
                scrollTop: 0,
            }, 400);
          }

        }
      });
      //The method call
      indicator.getOption('preventMouse');
    }
  }


  //slider-projects

  var projectSlider = $('.sort-projects__slider-projects');

  projectSlider.slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    infinite: false,
    dots: false,
    arrows: true,
    variableWidth: true,
    responsive: [
    {
      breakpoint: 1025,
      settings: {
        variableWidth: false,
        dots: false,
        slidesToShow: 3,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 931,
      settings: {
        variableWidth: false,
        dots: false,
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 769,
      settings: {
        arrows: false,
        variableWidth: false,
        dots: true,
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 641,
      settings: {
        arrows: false,
        variableWidth: false,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    }],
  });

  //sort-projects
  const tagProjects = $('.tag-panel__tag-projects');

  //disabled tag without content
  $(tagProjects).each(function( elem ) {
    let filter = $(this).attr('data-filter');
    if(!$('.slider-projects__slick-slide'+ filter).length && filter !== 'all') {
      $(this).attr('disabled', true);
    }
  });

  //sort
   tagProjects.on('click', function () {
     let filter = $(this).attr('data-filter');
     let slider = $('.sort-projects__slider-projects');

     $(tagProjects).removeClass('tag-projects--active');
     $(this).addClass('tag-projects--active');

     if (filter === 'all') {
       slider.slick('slickUnfilter');
       slider.slick('slickGoTo', 1);
     } else {
       slider.slick('slickUnfilter');
       slider.slick('slickFilter', filter);
       slider.slick('slickGoTo', 1);
     }

     calcDotsWidth();

   });

  //count of news/projecs on mobile
  var countMobileNews = 4;
  var countNews = $('.sort-grid__item').length;
  if ($(window).width() <= 576 ) {
    $('.sort-grid__item').slice(countMobileNews, countNews).hide();
  }

  //transfer sidebar on project-page

  if($(window).width() <=768) {
    const sidebarContent = $('.sidebar--projects').html();
    $('.projects-article__sidebar-content').html(sidebarContent);
  }

  if($(window).width() <=768) {
    const sidebarContent = $('.sidebar--reviews-single').html();
    $('.content-reviews__sidebar-content').html(sidebarContent);
  }

  //copy service-step-title
  const stepTitle = $('.process-steps__list .step__title');
  stepTitle.each(function () {
    $(this).clone().appendTo($(this).parents('.list__step'));
  });


  //vacancy dropdawn
  $('.vacancy-item__header').on('click', function () {
    let item = $(this).parents('.dropdawn-menu__vacancy-item');
    if (item.hasClass('item--active')) {
      item.removeClass('item--active');
      item.find('.vacancy-item__vacancy-info').slideUp(250);
    } else {
      $('.dropdawn-menu__vacancy-item').removeClass('item--active');
      $('.vacancy-item__vacancy-info').slideUp(250);
      item.addClass('item--active');
      item.find('.vacancy-item__vacancy-info').slideDown(250);
    }

  });

  // create offices dropdawn list

  $('.branch-offices__grid .grid__office-item').each(function () {
    let officeName = $(this).find('.office-item__title').html();
    let officeID = $(this).attr('data-office-id');
    let coordPlace = $(this).attr('data-coordPlace');
    $('.float-contacts__offices-list').append(
      '<li class="offices-list__item" data-office-id="' + officeID + '" data-coordPlace="'+ coordPlace +'">' + officeName + '</li>'
    );
  });

  //dropdawn offices list

  //set Head Office Contacts
  const headOfficeId = $('.float-contacts__offices-list').attr('data-head-office-id');
  const defaultName = $('.float-contacts__offices-list').attr('data-default-name');
  setOfficeContacts(headOfficeId);

  function setOfficeContacts(officeID) {
    let contactsBlock = $('.float-contact__offices .offices__office-item');
    let officeContacts = $('.grid__office-item[data-office-id="' + officeID + '"]').html();
    contactsBlock.html(officeContacts);
  };

  $('.offices-list__item').on('click', function () {
    const mainName = $('.offices-list__item.item--current.item--first').attr('data-head-name');
    let officesList = $(this).parents('.float-contacts__offices-list');

    if (officesList.hasClass('list--active')) {
      officesList.removeClass('list--active');
      if($(this).index() != 0) {
        $('.offices-list__item').removeClass('item--current');
        $(this).addClass('item--current');
      } else {
        $(this).html(mainName);
      }

      //подтягиваем контктные данные выбранного офиса
      let officeID = $(this).attr('data-office-id');
      setOfficeContacts(officeID);

    } else {
      officesList.addClass('list--active');
      $('.offices-list__item').addClass('item--visible');
      $('.offices-list__item.item--first').html(defaultName);
    }
  });


  //structure grid
  if ($('.structure-grid__departments').length) {
    halfLine();

    $(window).on('resize', function () {
      halfLine();
    });

    function halfLine() {
      if ($(window).width() > 480) {
        $('.departments__item').removeClass('item--half-line');
        var blockWidth = $('.structure-grid__departments').width();
        var elemWidth = $('.structure-grid__departments').find('.departments__item').width();
        var countInRow = (blockWidth / elemWidth).toFixed(0);
        $('.departments__item:nth-of-type(' + countInRow + 'n+'+ countInRow +')').addClass('item--half-line');
      }
    }
  }


  //tab cleanin steeps
  const cleaningTabBtn = $('.tabs-process__tab-btn');
  const cleaningTabs = $('.tabs-process__tab');
  cleaningTabBtn.on('click', function () {
    const tabID = '#' + $(this).attr('data-tab');
    cleaningTabBtn.removeClass('tab-btn--active');
    $(this).addClass('tab-btn--active');
    cleaningTabs.removeClass('tab--active');
    $(tabID).addClass('tab--active');
  })

});
