$(document).ready(function () {
  const form = $('.tank-cleaning__quiz');
  const quizItem = $('.quiz__item');
  const itemCount = quizItem.length;
  const quizBtn = $('.quiz__nav-button');
  const nextBtn = $('.quiz__nav-button.button--next');
  const prevBtn = $('.quiz__nav-button.button--prev');
  const quizInput = $('.quiz__input');
  const quilLabel = $('.quiz__label');
  var phoneInput = document.querySelectorAll('.input--phone');

  const quizHeader = $('.quiz__item-header');
  const transStep = quizHeader.attr('data-trans-step');
  const transFrom = quizHeader.attr('data-trans-from');

  // == lokalize ==
  const step = gettext(`${transStep} %s ${transFrom} %s`);
  // == /lokalize ==

  disableButton(prevBtn);

  phoneInput.forEach(function (element) {
    var phoneMask = IMask(
      element, {
        mask: '+{0}(000)-000-00-00',
        placeholderChar: '_',
        lazy: false,
        overwrite: false,
      });
  });

  quizItem.each(function (index) {
    let elem = $(this);
    setQuizCounter(elem, index + 1);
    setItemsID(elem, index + 1);
  });

  quizBtn.on('click', function (e) {
    e.preventDefault();

    let currentQuizItem = $('.quiz__item.item--current');
    let quizInputs = currentQuizItem.find('.quiz__item-inputs');
    let currentQuizId = currentQuizItem.attr('id');
    let currentID = currentQuizId.split('-');
    currentID = +currentID[currentID.length - 1];

    let nextID;

    if ($(this).hasClass('button--next')) {
      nextID = currentID + 1;
      if (nextID <= itemCount) {
        if (validateInputs(currentQuizItem)) {
          quizInputs.removeClass('inputs--error');
          goToQuiz(nextID);
          setNav(nextID);
        } else {
          quizInputs.addClass('inputs--error');
        }
      } else {
        if (validateInputs(currentQuizItem)) {
          quizInputs.removeClass('inputs--error');

          let data = new FormData(form[0]);
          let url = form.attr('action');

        $.ajax(url, {
          method: 'post',
          data: data,
          processData: false,
          contentType: false,
          success: function () {

          }
        });

        $('.modal--thanks .thank-text--request').show();
        $.fancybox.open({
            src: '#modal-thanks',
            opts: {
              afterLoad: function () {
                let fancyboxSlide = document.querySelectorAll('.fancybox-slide');
                fancyboxSlide.forEach(function (element) {
                  bodyScrollLock.disableBodyScroll(element);
                });
              },
              beforeClose: function () {
                bodyScrollLock.clearAllBodyScrollLocks();
              }
            }
          },
          {
            autoFocus: false,
            touch: false,
          });
        form.trigger('reset');
        goToQuiz(1);
        setNav(1);
        } else {
          quizInputs.addClass('inputs--error');
        }
      }
    } else {
      nextID = currentID - 1;
      quizInputs.removeClass('inputs--error');
      goToQuiz(nextID);
      setNav(nextID);
    }

  });

  quizInput.on('input', function () {
    clearError($(this));
  });

  quilLabel.on('click', function () {
    clearError($(this));
  });

  function clearError(elem) {
    elem.closest('.quiz__item-inputs').removeClass('inputs--error');
  }

  function setQuizCounter(elem, id) {
    let counter = '<span class="quiz__item-counter"> (' +
                  (interpolate(step, [id, itemCount])) +
                  ')</span>';
    elem.find('.quiz__item-header').append(counter);
  }

  function setItemsID(elem, id) {
    elem.attr('id', 'quiz-item-' + id);
  }

  function validatePhone(str) {
    let countNum = 0;
    for (var i = 0; i < str.length; i++) {
      if (!isNaN(str[i]) && str[i] !== ' ') {
        countNum++;
      }
    }
    if (countNum == 11) {
      return true;
    } else {
      return false;
    }
  };

  function validateInputs(quizItem) {
    let errorField = true;
    let errorRadio = true;
    quizItem.find('.quiz__input').each(function () {
      let value = $(this).val();
      value = (value || '').trim();
      if (value !== '') {

        if ($(this).hasClass('input--phone')) {
          if (validatePhone(value)) {
            errorField = false;
          }
        } else {
          errorField = false;
        }

      }
    });
    quizItem.find('.quiz__radio').each(function () {
      let check = $(this).prop('checked');
      if (check) {
        errorRadio = false;
        return false;
      }
    });

    return (errorField && errorRadio) ? false : true;
  }

  function disableButton(button) {
    button.addClass('button--hidden');
    button.attr('disabled', true);
  }

  function enableButton(button) {
    button.removeClass('button--hidden');
    button.removeAttr('disabled');
  }

  function setNav(nextID) {
    enableButton(prevBtn);
    enableButton(nextBtn);
    if (nextID === 1) {
      disableButton(prevBtn);
    }
  }

  function goToQuiz(id) {
    quizItem.removeClass('item--current');
    $('#quiz-item-' + id).addClass('item--current');
  }


});