$(document).ready(function () {

  const sliderGallery = $('.slider-gallery');
  const sliderThumbnail = $('.slider-thumbnails');
  const slides = sliderGallery.find('.slider-gallery__item');

  if ($(window).width() > 680) {


    slides.map(function (index) {
      const src = $(this).find('.slider-gallery__item-photo').attr('src');
      const img = '<img class="slider-thumbnails__item-photo" src="'+ src +'" alt="slider-thimbnails">';
      const thumbnail = '<div class="slider-thumbnails__item" data-slide="'+ index +'">'+ img +'</div>';
      sliderThumbnail.append(thumbnail);
    });

    sliderGallery.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      infinite: false,
      dots: false,
      arrows: true,
      asNavFor: sliderThumbnail,
    });

    sliderThumbnail.slick({
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: false,
    infinite: false,
    dots: false,
    arrows: false,
    asNavFor: sliderGallery,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 5,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
        }
      },
    ]

  });

    sliderThumbnail.on('click', '.slider-thumbnails__item' , function () {
    const slideIndex = $(this).attr('data-slide');
    sliderGallery.slick('slickGoTo', slideIndex);
  });

  }

  //select current thumbnail
  // sliderGallery.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
  //   sliderThumbnail.find('.slider-thumbnails__item[data-slide="'+ currentSlide +'"]').removeClass('item--current');
  //   sliderThumbnail.find('.slider-thumbnails__item[data-slide="'+ nextSlide +'"]').addClass('item--current');
  // });

  if (slides.length < 2) {
    sliderThumbnail.hide();
  }

});

