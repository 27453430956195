$(document).ready(function() {

  const modal = '#modal-typo',
        sendText = $(modal +' .form__send-text');


  function getSelectedText(){
    if (window.getSelection) {
        text = window.getSelection();
    }else if (document.getSelection) {
        text = document.getSelection();
    }else if (document.selection) {
        text = document.selection.createRange().text;
    }

    let span = document.createElement('span');
    $(span).addClass('typo');
    let range = text.getRangeAt(0).cloneRange();
    range.surroundContents(span);
    text.removeAllRanges();
    text.addRange(range);
    return text.toString();
  }


  var isCtrl = false;
  $(document).keyup(function (e) {
        if(e.which == 17) isCtrl = false;
    }).keydown(function (e) {
        if(e.which == 17) isCtrl=true;
        if(e.which == 13 && isCtrl == true) {

        //insert text
        let selected = getSelectedText();
         let typo = $('.typo');
         let selectedText = typo.text();
         let fullText = typo.parent().html();
         let firstEntry = fullText.indexOf(selected);

         let startSubStr = (firstEntry > 60) ? firstEntry - 60 : 0;
         let endSubStr = firstEntry + selected.length + 50;
         let shortText = fullText.slice(startSubStr, endSubStr);


         if (firstEntry > 60) {
           shortText =  '...' + shortText;
         }
         if (fullText.length > endSubStr) {
           shortText =  shortText + '...';
         }

         console.log(fullText.length);
         console.log(selected.length + 50);

         sendText.html(shortText);

         $('#typo').val(selectedText);
         $('#typo-full-text').val(shortText);
         $('#typo-url').val(window.location.href);

          //call modal
           $.fancybox.open({
          src: '#modal-typo',
          opts: {
            afterLoad: function () {
              let fancyboxSlide = document.querySelectorAll('.fancybox-slide');
              fancyboxSlide.forEach(function (element) {
                bodyScrollLock.disableBodyScroll(element);
              });
            },
            beforeClose: function () {
              bodyScrollLock.clearAllBodyScrollLocks();
              let typo = $('.typo').contents();
              typo.unwrap();
            }
          }
        },
        {
          autoFocus: false,
          touch: false,
        });

            isCtrl = false;
        }
    });
});
