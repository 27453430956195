$(document).ready(function () {

 const controlsBlock = $('.tabs-block__info-controls');
 const video = $('.tabs-block__video');
 const content =  $('.tabs-block__content-item');
 const process = $('.tabs-block__info-controls-progress');
 const wWidth = $(window).width();

 video.each(function (index) {
  let videoID = $(this).attr('id');

  const button = '<button class="tabs-block__info-controls-button" data-video="'+ videoID +'">'+ (index + 1) +'</button>';
  controlsBlock.append(button);

 });

 const videoButtons = $('.tabs-block__info-controls-button');
 videoButtons.first().addClass('button--active');

 videoButtons.on('click', function () {
  let dataVideo = $(this).attr('data-video');

  videoButtons.removeClass('button--active');
  $(this).addClass('button--active');

  video.removeClass('video--current');
  content.removeClass('item--current');
  $('#'+ dataVideo).addClass('video--current');
  $('.tabs-block__content-item[data-content="'+ dataVideo +'"]').addClass('item--current');

  let processPosition = controlsBlock.outerWidth() * 0.25 * ($(this).text() - 1);
  process.css({'left': processPosition});
 });

 //  setContentMaxHeight();
 //
 //  $(window).resize(function () {
 //   setContentMaxHeight();
 //  });
 //
 // function setContentMaxHeight() {
 //  if (wWidth > 1024 ) {
 //    let videoHeight = video.outerHeight();
 //    let controlHeight = controlsBlock.outerHeight();
 //    $('.tabs-block__content').css({'max-height': videoHeight - controlHeight});
 //   }
 // }

});