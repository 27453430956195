$('documen').ready(function () {

  const unlockBtn = $('.projects-table__unlock-btn');
  const wrapperTable = $('.projects-table__wrapper');
  const tabBtn = $('.projects-table__tab-switcher');
  const table = $('.projects-table__table');

  //lock table
  unlockBtn.on('click', function () {
    unlockTable();
  });

  const unlockTable = () => {
    wrapperTable.removeClass('table--lock');
  };

  const lockTable = () => {
    wrapperTable.addClass('table--lock');
  };

  $(document).click(function (e) {
    if ( !wrapperTable.is(e.target) && wrapperTable.has(e.target).length === 0) {
      lockTable();
    };
  });

  //tabs
  tabBtn.on('click', function () {
    wrapperTable.scrollTop(0);
    const tabID = $(this).attr('data-tab');
    tabBtn.removeClass('switcher--active');
    table.removeClass('table--active');
    $(this).addClass('switcher--active');
    $(tabID).addClass('table--active');
  });

});