$(document).ready(function () {

  const widget = $('.sidebar__widget.widget--photo');


  // create sidebar photo-list
  if (widget.length) {
    const photos = $('.gallery__photo');
    const title = $('.page-title').text();

    photos.map(function (index) {
      const id = 'gallery-photo-' + index;
      const src = $(this).attr('src');
      $(this).attr('id', id);
      const widgetPhoto = '<img class="widget__gallery-photo" src="' + src
                                + '" alt="'+ title +'">';
      const widgetLink = '<a class="widget__gallery-link" href="#' + id +'">' + widgetPhoto +'</a>';

      widget.append(widgetLink);
    });

     const widgetPhoto = $('.widget__gallery-photo');
     const widgetLink = $('.widget__gallery-link');


     getActivePhoto();

    //smooth scroll to anchor
    widgetLink.on('click', function (e) {
      e.preventDefault();
       scrollToAnchor($(this));
      // widgetPhoto.removeClass('selected');
      // $(this).children('.widget__gallery-photo').addClass('selected');

    });

    function scrollToAnchor(elem) {
        $('html, body').stop().animate({
          scrollTop: $(elem.attr('href')).offset().top - 20
        }, 400);
    }

    function getActivePhoto() {
      let lastId;
      let fromTop = $(this).scrollTop() + 30;
      let currentItem = photos.map(function () {
        if ($(this).offset().top <= fromTop ) {
          return($(this));
        }
      });
      currentItem = currentItem[currentItem.length-1];
      let id = currentItem && currentItem.length ? currentItem[0].id : "";
      if (lastId !== id) {
         lastId = id;
         if  (fromTop < 400) {
           widgetLink.first().children().addClass('selected');
          } else {
           widgetPhoto.removeClass('selected');
         }
         $('.widget__gallery-link[href="#' + id + '"] .widget__gallery-photo').addClass('selected');
      }
    }

    $(window).scroll(function() {
      getActivePhoto();
    });

  }

});