$(document).ready(function () {
  const sliderNewsGallery = $('.news-gallery-slider');

  sliderNewsGallery.slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: false,
    infinite: true,
    dots: false,
    arrows: true,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1160,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  });

});