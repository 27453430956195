$(document).ready(function () {

  $('.video__play-button, .video__player').on('click touchstart', function (e) {
    e.preventDefault();
    const video = $(this).closest('.services__video').find('.video__player').get(0);
    const videoBlock = $(this).closest('.services__video').find('.video__player');
    videoBlock.removeClass('player--no-controls');
    if (video.paused === false) {
      video.pause();
    } else {
      video.play();
      $('.video__play-button').css({'display': 'none'});
    }
  });

});