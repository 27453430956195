  //custom select


function setSelectcStyle() {

  $('.form__select:not(.select-hidden)').each(function () {
    var $this = $(this), numberOfOptions = $(this).children('option').length;

    $this.addClass('select-hidden');
    $this.wrap('<div class="select"></div>');
    $this.after('<div class="select-styled"></div>');

    var $styledSelect = $this.next('div.select-styled');
    $styledSelect.text($this.children('option').eq(0).text());

    var $list = $('<ul />', {
      'class': 'select-options'
    }).insertAfter($styledSelect);

    for (var i = 0; i < numberOfOptions; i++) {
      $('<li />', {
        text: $this.children('option').eq(i).text(),
        rel: $this.children('option').eq(i).val()
      }).appendTo($list);
    }

    var $listItems = $list.children('li');

    $styledSelect.click(function (e) {
      e.stopPropagation();
      $('div.select-styled.select--active').not(this).each(function () {
        $(this).removeClass('select--active').next('ul.select-options').hide();
      });
      $(this).toggleClass('select--active').next('ul.select-options').toggle();
    });

    $listItems.click(function (e) {
      e.stopPropagation();
      $styledSelect.text($(this).text()).removeClass('select--active');
      $this.val($(this).attr('rel'));
      $list.hide();

      //добавляем класс что был сделан выбор
      $(this).closest('.form__input-wrapper, .form__select-wrapper').find('.select').addClass('select--changed');
      $(this).closest('.form__input-wrapper').find('.form__input-outer').removeClass('field--invalid');
      $(this).closest('.form__input-wrapper').find('.form__error-msg').hide();

      //устанавливаем значение select
      let options = $(this).attr('rel');
      $(this).closest('.form__input-wrapper').find('.form__select option').attr('selected', false );
      $(this).closest('.form__input-wrapper').find('.form__select option[value="' + options +'"]').attr('selected', true);
    });

    $(document).click(function () {
      $styledSelect.removeClass('select--active');
      $list.hide();
    });

  });
};
