$(document).ready(function () {

  const tagSort = $('.drop-tag-panel__tag');
  const dropPanel = $('.sorting-block__drop-tag-panel');

  const scrollBtn = $('.drop-tag-panel__button-scroll');
  const ScrollBtnBack = $('.drop-tag-panel__button-scroll.button-scroll--back');
  const ScrollBtnForw = $('.drop-tag-panel__button-scroll.button-scroll--forw');

  //disabled tag without content

  tagSort.on('click', function (e) {

    $('.drop-tag-panel__tag').removeClass('tag--active');
    $(this).addClass('tag--active');
    //drop down panel
    if ($(window).width() <= 1200 && !dropPanel.hasClass('drop-tag-panel--active')) {
      e.preventDefault();
      dropPanel.addClass('drop-tag-panel--active');
      tagSort.addClass('tag--visible');
    } else {
      dropPanel.removeClass('drop-tag-panel--active');
      tagSort.removeClass('tag--visible');
      document.location.href = $(this).attr('href');
    }

  });

  //click without
  $(document).mouseup(function (e) {
		if (!tagSort.is(e.target)
		    && tagSort.has(e.target).length === 0) {
			tagSort.removeClass('tag--visible');
			$('.sorting-block__drop-tag-panel').removeClass('drop-tag-panel--active');
		}
	});

  $(window).scroll(function() {
    tagSort.removeClass('tag--visible');
		$('.sorting-block__drop-tag-panel').removeClass('drop-tag-panel--active');
  });

  //scroll tags
  if (dropPanel.length) {

    if ($(window).width() > 1200) {
      const lastTag = $('.drop-tag-panel__tag:last-of-type');
      const contentWidth = (lastTag.offset().left - dropPanel.offset().left) + lastTag.width();

      if (contentWidth > dropPanel.width()) {
        const currentTag = $('.drop-tag-panel__tag.tag--active');
        const tagLeftOffset = currentTag.offset().left - dropPanel.offset().left;

        if ((tagLeftOffset + currentTag.width()) > dropPanel.width()) {
          scrollForward(0);
        } else {
          scrollBack(0);
        }
      } else {
        scrollBtn.parent().hide();
      }
    }

  }

  ScrollBtnBack.on('click',function () {
    scrollBack(300);
  });

  ScrollBtnForw.on('click', function() {
    scrollForward(600);
  });

  function scrollForward(duration) {
    let step = dropPanel.width();
    ScrollBtnBack.parent().show();
    dropPanel.animate({
      scrollLeft: step + "px"
    }, duration);
    ScrollBtnForw.parent().hide();
  }

  function scrollBack(duration) {
    ScrollBtnForw.parent().show();
    dropPanel.animate({
      scrollLeft: "0px"
    }, duration);
    ScrollBtnBack.parent().hide();
  }


});




